import i18next, { TOptionsBase } from 'i18next';
import { initReactI18next } from 'react-i18next';
import toLower from 'lodash/toLower';
import toUpper from 'lodash/toUpper';

import languageDetector from './languageDetector';
import translationLoader from './translationLoader';
import * as config from './config';

i18next
  .use(languageDetector)
  .use(translationLoader)
  .use(initReactI18next) // Pass i18n down to react-i18next
  .init({
    fallbackLng: config.fallbackLng,
    ns: config.namespaces,
    defaultNS: config.defaultNamespace,
    load: 'currentOnly', // Only load current language
    debug: false,
    interpolation: {
      format: (value, format, lng) => { // eslint-disable-line @typescript-eslint/no-unused-vars
        if (format === 'lowercase') {
          return toLower(value);
        }

        if (format === 'uppercase') {
          return toUpper(value);
        }

        return value;
      },
      escapeValue: false, // Not needed for React
    },
  });

const t = (key: string, options?: TOptionsBase) => i18next.t(key, options);

const getLocale = () => i18next.language;

const onLanguageChanged = (fn: (...args: any[]) => void) => i18next.on('languageChanged', fn);

const changeLanguageAsync = (language: string) => i18next.changeLanguage(language);

export {
  t,
  getLocale,
  onLanguageChanged,
  changeLanguageAsync,
};

export default i18next;
